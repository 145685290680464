import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Box, makeStyles } from '@material-ui/core'
import { FluidObject } from 'gatsby-image'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const useStyles = makeStyles(theme => ({
  carouselItem: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: `calc((100vw - ${theme.spacing(4)}px) * 0.5625)`,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: `calc((100vw - ${theme.spacing(6)}px) / 2.35)`,
    },
    [theme.breakpoints.up('lg')]: {
      height: `524.2553px`,
    },
  },
  wrapper: { backgroundColor: theme.palette.common.black },
}))

type Props = {
  images?: {
    link?: string
    blank?: boolean
    fluid?: FluidObject
  }[]
}

const TopImages: React.FC<Props> = ({ images }) => {
  const classes = useStyles()
  return (
    <Carousel
      showStatus={false}
      showThumbs={false}
      interval={4000}
      autoPlay
      infiniteLoop
    >
      {images?.map((image, i) => (
        <Box className={classes.wrapper} key={image?.fluid?.src}>
          {image.link ? (
            <OutboundLink
              href={image.link}
              target={image.blank ? '_blank' : undefined}
              rel={image.blank ? 'noopener noreferrer' : undefined}
            >
              <GatsbyImage
                className={classes.carouselItem}
                fluid={image.fluid}
                objectFit="contain"
                loading={i === 0 ? 'eager' : 'lazy'}
                durationFadeIn={100}
                alt={`トップ画像${i}`}
              />
            </OutboundLink>
          ) : (
            <GatsbyImage
              className={classes.carouselItem}
              fluid={image.fluid}
              objectFit="contain"
              loading={i === 0 ? 'eager' : 'lazy'}
              durationFadeIn={100}
            />
          )}
        </Box>
      ))}
    </Carousel>
  )
}

export default TopImages
