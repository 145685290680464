import React from 'react'
import { graphql, PageProps, Link } from 'gatsby'

import Layout from '../components/Layout'
import { IndexQuery } from '../../types/graphql-types'
import { render } from '../utils/render'
import TopImages from '../components/TopImages'
import { Chapter } from '../components/Typography'
import { Grid, makeStyles, Button } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import AllBlogPost from '../components/AllBlogPost'

const useStyles = makeStyles(theme => ({
  post: { marginBottom: theme.spacing(4) },
  image: { objectFit: 'cover', width: 150, height: 150 },
  blogLink: { width: '100%', textAlign: 'right' },
}))

const BlogIndex: React.FC<PageProps<IndexQuery>> = ({ data }) => {
  const images = data.allMicrocmsIndexImages.edges.map(({ node }) => ({
    link: node.link,
    blank: node.blank,
    fluid: node.localImage?.childImageSharp?.fluid,
  }))
  const classes = useStyles()

  return (
    <Layout>
      <TopImages images={images} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {render(data.microcmsIndex?.home)}
        </Grid>
        <Grid item xs={12}>
          <Chapter>Blog</Chapter>
          <AllBlogPost
            posts={data.allMicrocmsBlogs.edges}
            categories={data.allMicrocmsCategories.edges}
          />
        </Grid>
        <Grid sm={12} className={classes.blogLink}>
          <Button component={Link} to="/blog/" endIcon={<ArrowForwardIcon />}>
            ブログ一覧
          </Button>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default BlogIndex

export const query = graphql`
  query Index {
    microcmsIndex {
      home
    }
    allMicrocmsIndexImages {
      edges {
        node {
          link
          blank
          localImage {
            childImageSharp {
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    file(relativePath: { eq: "logo_twitter.png" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMicrocmsBlogs(sort: { fields: date, order: DESC }, limit: 3) {
      edges {
        node {
          categories {
            name
            id
          }
          childConvertHtml {
            plainText
          }
          date
          blogsId
          title
          localThumbnail {
            childImageSharp {
              fixed(width: 150, height: 150, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allMicrocmsCategories {
      edges {
        node {
          name
          categoriesId
        }
      }
    }
  }
`
